import React, { useContext, useEffect } from "react";
import css from "../styles/Confirmation.module.scss";
import DropOffOrder from "../components/Confirmation/DropOffOrder";
import SendOrder from "../components/Confirmation/SendOrder";
import B2BOrder from "../components/Confirmation/B2BOrder";
import { DeliveryContext } from "../context/DeliveryContext";
import { ShowFooterContext } from "../context/ShowFooterContext";

const Confirmation: React.FC = () => {
  const { deliveryData } = useContext(DeliveryContext);
  const { setShowFooter } = useContext(ShowFooterContext);

  window.scrollTo({
    top: 0,
  });

  useEffect(() => {
    setShowFooter(true);
  }, []);

  return (
    <main className={css.Confirmation}>
      <div className={css.IntroText}>
        {!deliveryData.isCompanyOrder ? (
          <p className={css.Price}>
            <span>Vipps {deliveryData.totalPrice} kr</span> til 752370
          </p>
        ) : (
          <div className={css.ImageContainer}>
            <img
              src="images/illustration/confirmation-waiting.svg"
              alt="confirmation-waiting"
            />
          </div>
        )}
      </div>
      {deliveryData.isCompanyOrder ? (
        <B2BOrder />
      ) : deliveryData.isSent ? (
        <SendOrder />
      ) : (
        <DropOffOrder />
      )}
    </main>
  );
};

export default Confirmation;
