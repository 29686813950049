import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import css from "../styles/Navbar.module.scss";
import NavLink from "./NavLink";
import { ShowFooterContext } from "../context/ShowFooterContext";
import { RenderHomeComponentsContext } from "../context/RenderHomeComponentsContext";

export interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const Navbar: React.FC<Props> = function ({ open, setOpen }) {
  const navigate = useNavigate();
  const { setShowFooter } = useContext(ShowFooterContext);
  const { setRenderHomeComponents } = useContext(RenderHomeComponentsContext);
  const navRef = useRef<HTMLElement>(null);

  const goToHome = () => {
    navigate("/");
    setShowFooter(true);
    setRenderHomeComponents(true);
  };

  return (
    <nav
      ref={navRef}
      className={css.Navbar}
      style={!open ? { position: "relative" } : { position: "absolute" }}
    >
      <div className={css.Navheader}>
        <a href="#" onClick={goToHome}>
          <img
            src="images/logo.svg"
            height="22px"
            width="62px"
            alt="logo"
            className={css.Logo}
          />
        </a>

        <button
          onClick={() => {
            setOpen(!open);
          }}
        >
          {open ? (
            <img
              src={"images/exit-cross.svg"}
              alt="cross-exit"
              className={css.ExitCross}
            />
          ) : (
            <img
              src={"images/hamburger-menu.svg"}
              alt="hamburger-menu"
              className={css.HamburgerMenu}
            />
          )}
        </button>
      </div>

      {open && (
        <div className={css.NavLinkContainer}>
          <NavLink
            text={"For bedrifter"}
            link={"https://blogg-fikse.squarespace.com/for-bedrifter"}
            icon={true}
          />
          <NavLink
            text={"Prisliste"}
            link={"https://blogg-fikse.squarespace.com/prisliste"}
            icon={true}
          />
          <NavLink
            text={"Bli reparatør"}
            link={"https://blogg-fikse.squarespace.com/for-reparatorer"}
            icon={true}
          />
          <NavLink
            text={"Våre studio"}
            link={"https://blogg-fikse.squarespace.com/her-er-vi"}
            icon={true}
          />
          <NavLink
            text={"Om oss"}
            link={"https://blogg-fikse.squarespace.com/about"}
            icon={true}
          />
        </div>
      )}
    </nav>
  );
};

export default Navbar;
