import React, { useState, useContext, useEffect } from "react";
import SectionNavText from "./SectionNavText";
import SectionProductText from "./SectionProductText";
import css from "../styles/NavListContainer.module.scss";
import { OrderContext, OrderContextType } from "../context/OrderContext";
import RepairForm from "./RepairForm/RepairForm";
import { ShowFooterContext } from "../context/ShowFooterContext";
import { OrderStateContext } from "../context/OrderStateContext";
import { RenderHomeComponentsContext } from "../context/RenderHomeComponentsContext";
import { CategoryContext } from "../context/CategoryContext";
import { Product, DataListType } from "../types";

export interface Props {
  problems: DataListType[];
}

const NavListContainer: React.FC<Props> = function ({ problems }) {
  const [productlist, setProductlist] = useState<DataListType>();
  const { setShowFooter } = useContext(ShowFooterContext);
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const { orderState, setOrderState } = useContext(OrderStateContext);
  const { renderHomeComponents, setRenderHomeComponents } = useContext(
    RenderHomeComponentsContext
  );
  const { category } = useContext(CategoryContext);
  const [currentCategory, setCurrentCategory] = useState<string>("klær");

  const { storage } = useContext(OrderContext) as OrderContextType;

  const callbackProduct = (array: DataListType | undefined) => {
    setProductlist(array);
    setRenderHomeComponents(false);
    setShowFooter(false);
  };

  const showRepairForm = (product: Product) => {
    setCurrentProduct(product);
    setOrderState(true);
  };

  const toggleStates = () => {
    setRenderHomeComponents(true);
    setShowFooter(true);
  };

  const categoryTranslations = [
    { category: "clothes", translation: "klær" },
    { category: "shoes", translation: "sko" },
    { category: "accessories", translation: "tilbehør" },
    { category: "electronics", translation: "småelektronikk" },
  ];

  useEffect(() => {
    const categoriesArray = categoryTranslations.filter(
      (c) => c.category === category
    );
    setCurrentCategory(categoriesArray[0].translation);
  }, [category]);

  return (
    <>
      <ul className={css.NavListContainer}>
        {renderHomeComponents
          ? problems.map((problem: DataListType, key: number) => (
              <SectionNavText
                key={key}
                problem={problem.name}
                callback={() => callbackProduct(problem)}
              />
            ))
          : productlist?.product?.map((product: Product, key: number) => (
              <SectionProductText
                key={key}
                text={product.type}
                product={product}
                number={
                  storage.filter((item) => item.product === product.type).length
                }
                showRepairForm={() => showRepairForm(product)}
              />
            ))}
      </ul>
      {!renderHomeComponents && (
        <div className={css.SectionBackClick}>
          <button onClick={() => toggleStates()}>
            <img
              src="images/left-faced-vector.svg"
              alt="left-faced-vector"
              className={css.Icon}
            />
            <p>Tilbake til {currentCategory}</p>
          </button>
        </div>
      )}
      {orderState && currentProduct && (
        <RepairForm
          category={productlist?.name as string}
          product={currentProduct}
        />
      )}
    </>
  );
};

export default NavListContainer;
