import { useState, useEffect } from "react";
import { clothes, shoes, accessories } from "../utils/DataPricelist";

import { DataListType } from "../types";

export const useFetchData = (category: string) => {
  const [data, setData] = useState<Array<DataListType>>();

  useEffect(() => {
    if (category === "clothes") {
      setData(clothes);
    }

    if (category === "shoes") {
      setData(shoes);
    }

    if (category === "accessories") {
      setData(accessories);
    }
  }, [category]);

  return { data };
};
