import React, { useState, useContext } from "react";
import css from "../styles/CategoriesContainer.module.scss";
import CategoryBox from "./CategoryBox";
import { ShowFooterContext } from "../context/ShowFooterContext";
import { RenderHomeComponentsContext } from "../context/RenderHomeComponentsContext";
import { CategoryContext } from "../context/CategoryContext";

export interface Props {
  select: string;
  setSelect: (category: string) => void;
}

const CategoriesContainer: React.FC<Props> = function ({ setSelect }) {
  const [activeId, setActiveId] = useState<string>("");
  const { setCategory } = useContext(CategoryContext);

  const onChangeValue = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    setSelect(target.value);
  };
  const { setRenderHomeComponents } = useContext(RenderHomeComponentsContext);
  const { setShowFooter } = useContext(ShowFooterContext);

  const toggleStates = (e: HTMLInputElement, id: string) => {
    setCategory(e.value);
    setRenderHomeComponents(true);
    setShowFooter(true);
    setActiveId(id);
  };

  const data = [
    {
      text: "Klær",
      id: "clothes",
      imgPath: "images/illustration/category-clothes.svg",
      disabled: false,
      width: "150px",
    },
    {
      text: "Sko",
      id: "shoes",
      imgPath: "images/illustration/category-shoes.svg",
      disabled: false,
      width: "150px",
    },
    {
      text: "Tilbehør",
      id: "accessories",
      imgPath: "images/illustration/category-accessories.svg",
      disabled: false,
      width: "120px",
    },
    {
      text: "Småelektronikk",
      id: "electronics",
      imgPath: "images/illustration/category-electronics.svg",
      disabled: true,
      width: "120px",
    },
  ];

  return (
    <div className={css.CategoriesContainer} onChange={onChangeValue}>
      {data.map((d, i) => {
        return (
          <CategoryBox
            key={i}
            text={d.text}
            id={d.id}
            width={d.width}
            imgPath={d.imgPath}
            disabled={d.disabled}
            activeId={activeId}
            toggleStates={toggleStates}
          />
        );
      })}
    </div>
  );
};

export default CategoriesContainer;
