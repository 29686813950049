export const clothes = [
  {
    name: "Hull",
    product: [
      {
        type: "Overdel hull",
        smallRepair: 199,
        bigRepair: 349,
        descriptionSmall:
          "For små hull i enkle overdeler. NB! Lapping, ikke søm.",
        descriptionBig:
          "For hull eller revnet søm i overdeler av strikk eller plagg med flere hull. Gjelder også albupatching.",
        eligibleForExpress: true,
      },
      {
        type: "Underdel hull",
        smallRepair: 249,
        bigRepair: 399,
        descriptionSmall: "For små hull i enkle underdeler.",
        descriptionBig:
          "For underdeler med fór, eller for plagg med mer enn to hull.",
        eligibleForExpress: true,
      },
      {
        type: "Kjole hull",
        smallRepair: 249,
        bigRepair: 399,
        descriptionSmall: "For hull i søm etc.",
        descriptionBig: "For kjoler med fõr eller mer enn to hull.",
        eligibleForExpress: true,
      },
      {
        type: "Vinterjakke",
        smallRepair: 499,
        bigRepair: 1349,
        descriptionSmall:
          "Usynlig reparasjon av et lite hull i  vinterjakke, dunjakke, parkas.",
        descriptionBig:
          "Usynlig reparasjon av flere hull i  vinterjakke, dunjakke, parkas.",
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "Legge inn eller ut",
    product: [
      {
        type: "Overdel med feil vidde",
        smallRepair: 349,
        bigRepair: 549,
        descriptionSmall:
          "For plagg som skal sys inn eller legges ut med eksisterende stoff.",
        descriptionBig: "For plagg som skal legges ut med ekstra stoff",
        eligibleForExpress: true,
      },
      {
        type: "Underdel med feil vidde",
        smallRepair: 349,
        bigRepair: 499,
        descriptionSmall:
          "For plagg som skal sys inn eller legges ut med eksisterende stoff.",
        descriptionBig: "For plagg som skal legges ut med ekstra stoff",
        eligibleForExpress: true,
      },
      {
        type: "Skulderbredde",
        smallRepair: 299,
        bigRepair: 499,
        descriptionSmall:
          "For plagg som skal sys inn eller legges ut med eksisterende stoff.",
        descriptionBig: "For plagg som skal legges ut med ekstra stoff",
        eligibleForExpress: true,
      },
      {
        type: "Jakke med feil vidde",
        smallRepair: 699,
        bigRepair: 899,
        descriptionSmall:
          "For jakker eller blazere som skal sys inn eller legges ut med eksisterende stoff",
        descriptionBig:
          "For jakker eller blazere som skal legges ut med ekstra stoff",
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "Legge ned eller opp",
    product: [
      {
        type: "Arm med feil lengde",
        smallRepair: 299,
        bigRepair: 599,
        descriptionSmall: "For plagg med ett lag som skal legges opp.",
        descriptionBig:
          "For plagg som skal legges opp eller ned, og som har flere lag med stoff",
        eligibleForExpress: true,
      },
      {
        type: "Overdel med lengde",
        smallRepair: 349,
        bigRepair: 599,
        descriptionSmall: "For plagg med ett lag som skal legges opp.",
        descriptionBig:
          "For plagg som skal legges opp eller ned, og som har flere lag med stoff",
        eligibleForExpress: true,
      },
      {
        type: "Bukse med feil lengde",
        smallRepair: 269,
        bigRepair: 399,
        descriptionSmall: "For plagg med ett lag som skal legges opp.",
        descriptionBig:
          "For plagg som skal legges opp eller ned, og som har flere lag med stoff",
        eligibleForExpress: true,
      },
      {
        type: "Skjørt og kjole med feil lengde",
        smallRepair: 349,
        bigRepair: 699,

        descriptionSmall: "For plagg med ett lag som skal legges opp.",
        descriptionBig:
          "For plagg med flere lag som skal legges opp eller ned.",
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "Glidelås",
    product: [
      {
        type: "Jakke med glidelås",
        smallRepair: 449,
        bigRepair: 649,
        descriptionSmall: "For glidelåser opp til 30 cm.",
        descriptionBig: "For glidelåser lengre enn 30 cm",
        eligibleForExpress: true,
      },
      {
        type: "Underdel med glidelås",
        smallRepair: 329,
        bigRepair: 399,
        descriptionSmall: "For korte glidelåser opp til 15 cm.",
        descriptionBig: "For glidelåser lengre enn 15 cm",
        eligibleForExpress: true,
      },
      {
        type: "Kjole med glidelås",
        smallRepair: 329,
        bigRepair: 549,
        descriptionSmall: "For glidelåser opp til 20 cm.",
        descriptionBig: "For glidelåser lengre enn 20 cm",
        eligibleForExpress: true,
      },
      {
        type: "Overdel med glidelås",
        smallRepair: 299,
        bigRepair: null,
        descriptionSmall: "For glidelåser opp til 15 cm.",
        descriptionBig: null,
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "Rens",
    product: [
      {
        type: "Jakke",
        smallRepair: 399,
        bigRepair: 589,
        descriptionSmall: "Kort jakke NB! Ikke dunjakke",
        descriptionBig: "Dunjakke, lang jakke eller kåpe.",
        eligibleForExpress: true,
      },
      {
        type: "Dress",
        smallRepair: 269,
        bigRepair: 549,
        descriptionSmall: "For enten dressjakke eller dressbukse.",
        descriptionBig: "Dress eller smoking.",
        eligibleForExpress: true,
      },
      {
        type: "Bukse",
        smallRepair: 269,
        bigRepair: null,
        descriptionSmall:
          "Rens av de fleste typer bukser. Sjekk vaskelappen for å være sikker",
        descriptionBig: null,
        eligibleForExpress: true,
      },
      {
        type: "Kjole",
        smallRepair: 499,
        bigRepair: 1099,
        descriptionSmall: "Kort eller enkel kjole.",
        descriptionBig: "Selskapskjole eller lang kjole.",
        eligibleForExpress: true,
      },
      {
        type: "Overdel",
        smallRepair: 229,
        bigRepair: 269,
        descriptionSmall: "T-skjorte eller singlet.",
        descriptionBig: "Bluse med ermer eller genser.",
        eligibleForExpress: true,
      },
      {
        type: "Skjørt",
        smallRepair: 249,
        bigRepair: 399,
        descriptionSmall: "Kort skjørt.",
        descriptionBig: "Langt skjørt.",
        eligibleForExpress: true,
      },
      {
        type: "Tilbehør",
        smallRepair: 99,
        bigRepair: 169,
        descriptionSmall: "Luer, hansker og votter.",
        descriptionBig: "Skjerf",
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "Knapp",
    product: [
      {
        type: "Ny knapp",
        smallRepair: 69,
        bigRepair: 149,
        descriptionSmall:
          "For en knapp som skal festes eller byttes. Beskriv hvor dersom dette ikke fremgår på plagget, og dersom knappen mangler.",
        descriptionBig:
          "Opp til tre knapper. For flere, bestill to av denne. Skriv i feltet under om det skal lages flere knapphull og om du legger knapper ved.",
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "Søm",
    product: [
      {
        type: "Jakke søm",
        repair: 299,
        description: "For jakker som er gått opp i søm",
        eligibleForExpress: true,
      },
      {
        type: "Overdel søm",
        repair: 299,
        description: "For overdeler som er gått opp i søm",
        eligibleForExpress: true,
      },
      {
        type: "Kjole søm",
        repair: 299,
        description: "For kjoler som er gått opp i søm",
        eligibleForExpress: true,
      },
      {
        type: "Underdel søm",
        repair: 299,
        description: "For bukser, skjørt o.l som er gått opp i søm",
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "ReDesign",
    product: [
      {
        type: "ReDESIGN",
        repair: 1,
        description: "Besøk oss for priser og muligheter.",
        eligibleForExpress: true,
      },
    ],
  },
  {
    name: "Bunad",
    product: [
      {
        type: "Skulderbredde",
        repair: 1,
        description: "Disse type reparasjonen må avtales med skredder...",
        eligibleForExpress: false,
      },
      {
        type: "Sy inn eller legge ut",
        repair: 1,
        description: "Disse type reparasjonen må avtales med skredder...",
        eligibleForExpress: false,
      },
      {
        type: "Bukse eller vest",
        repair: 1,
        description: "Disse type reparasjonen må avtales med skredder...",
        eligibleForExpress: false,
      },
      {
        type: "Legge opp eller ned",
        repair: 1,
        description: "Disse type reparasjonen må avtales med skredder...",
        eligibleForExpress: false,
      },
      {
        type: "Søm eller hemp",
        repair: 1,
        description: "Disse type reparasjonen må avtales med skredder...",
        eligibleForExpress: false,
      },
      {
        type: "Rens",
        repair: 1,
        description: "Disse type reparasjonen må avtales med skredder...",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Skinnklær",
    product: [
      {
        type: "Skinnklær",
        repair: 1,
        description: "Skriv til oss",
        eligibleForExpress: false,
      },
    ],
  },
];

export const shoes = [
  {
    name: "Såle",
    product: [
      {
        type: "Hælflikk og lim",
        smallRepair: 299,
        bigRepair: 449,
        descriptionSmall: "Hvis du trenger å lime hælen.",
        descriptionBig: "Hvis du trenger en ny hælflikk, en sko eller et par",
        eligibleForExpress: false,
      },
      {
        type: "Såle foran",
        smallRepair: 299,
        bigRepair: 599,
        descriptionSmall: "Hvis du trenger å lime sålen, en sko eller et par.",
        descriptionBig: "Hvis du trenger ny halvsåle foran, per sko.",
        eligibleForExpress: false,
      },
      {
        type: "Hele sålen",
        smallRepair: 749,
        bigRepair: 1349,
        descriptionSmall:
          "Hvis du vil ha ny såle foran og ny hælflikk i gummi, ikke hel såle.",
        descriptionBig:
          "Hvis du vil bytte hele sålen på skoen i lær eller gummi. Skriv i feltet om du ønsker skomakersøm.",
        eligibleForExpress: false,
      },
      {
        type: "Underdel søm",
        repair: 299,
        smallRepair: null,
        bigRepair: null,
        description: "For bukser, skjørt o.l som er gått opp i søm",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Glidelås",
    product: [
      {
        type: "Bytte glidelås",
        smallRepair: 449,
        bigRepair: 649,
        descriptionSmall: "For sko med glidelåser opp til 15 cm, pris per sko.",
        descriptionBig:
          "For glidelåser over 15 cm til ridestøvler eller lignende, pris per sko.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Blokke ut",
    product: [
      {
        type: "Skinnsko",
        smallRepair: 349,
        bigRepair: 449,
        descriptionSmall:
          "Utvider skinnet ved foten. OBS! Her kommer det an på skinnet. Vi prøver så mye det går :)",
        descriptionBig:
          "Utvider skinnet rundt leggene, OBS! Her kommer det an på skinnet. Vi prøver så mye det går :)",
        eligibleForExpress: false,
      },
      {
        type: "Såle",
        smallRepair: 849,
        bigRepair: 999,
        descriptionSmall: "Blokker ut sålen med 20 mm.",
        descriptionBig: "Blokker ut sålen med 25 mm.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Hull",
    product: [
      {
        type: "Hull eller søm",
        smallRepair: 299,
        bigRepair: 399,
        descriptionSmall: "For ett lite hull eller ødelagt søm.",
        descriptionBig:
          "For to eller flere hull, eller ødelagt søm flere steder.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Rens og polering",
    product: [
      {
        type: "Rens og polering",
        repair: 349,
        smallRepair: null,
        bigRepair: null,
        description: "For rens eller polering av en sko eller ett par",
        eligibleForExpress: false,
      },
    ],
  },
];

export const accessories = [
  {
    name: "Stropp / reim",
    product: [
      {
        type: "Bytte (Bytte ødelagt stropp)",
        smallRepair: 649,
        bigRepair: 1119,
        descriptionSmall:
          "Passer til en liten veske under 20 cm. Husk å beskrive dine ønsker i emnefeltet.",
        descriptionBig:
          "Passer til større vesker over 20 cm. Husk å beskrive dine ønsker i emnefeltet.",
        eligibleForExpress: false,
      },
      {
        type: "Reparere (Reparere ødelagt stropp)",
        smallRepair: 449,
        bigRepair: 899,
        descriptionSmall:
          "Passer til en mindre reparasjon på reim. Husk å beskrive dine ønsker i emnefeltet.",
        descriptionBig:
          "Passer til en ødelagt reim flere steder. Husk å beskrive dine ønsker i emnefeltet.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Glidelås",
    product: [
      {
        type: "Glidelås",
        smallRepair: 499,
        bigRepair: 749,
        descriptionSmall: "Passer til glidelåser opp til 20 cm.",
        descriptionBig: "Passer til glidelåser lengre enn 20 cm.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Hull",
    product: [
      {
        type: "Hull",
        smallRepair: 299,
        bigRepair: 499,
        descriptionSmall: "Passer til ett hull.",
        descriptionBig: "Passer til flere hull.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Søm",
    product: [
      {
        type: "Søm",
        smallRepair: 349,
        bigRepair: 549,
        descriptionSmall: "Passer til en raknet søm under 10 cm.",
        descriptionBig:
          "Passer til stor raknet søm eller raknet søm flere steder.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Belte",
    product: [
      {
        type: "Belte",
        smallRepair: 299,
        bigRepair: 499,
        descriptionSmall:
          "Passer for enkle justeringer hvis det skal limes på baksiden. Husk å beskrive det godt i emnefeltet.",
        descriptionBig:
          "Passer for alle andre reparasjoner der skaden er synlig på fremsiden. Husk å beskrive det godt i emnefeltet.",
        eligibleForExpress: false,
      },
    ],
  },
  {
    name: "Veskelås",
    product: [
      {
        type: "Veskelås",
        smallRepair: 349,
        bigRepair: 499,
        descriptionSmall:
          "Passer hvis du skal bytte eller reparere en krok, veskelås eller beltespenne. Husk å beskrive det godt i emnefeltet.",
        descriptionBig:
          "Passer hvis flere låser, spenner e.l. på veskestropper skal byttes ut. Husk å beskrive det godt i emnefeltet.",
        eligibleForExpress: false,
      },
    ],
  },
];
