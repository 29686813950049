import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Confirmation from "./pages/Confirmation";
import OrderError from "./pages/OrderError";
import Layout from "./components/Layout";
import { OrderProvider } from "./context/OrderContext";
import { OrderStateContextProvider } from "./context/OrderStateContext";
import { DeliveryContextProvider } from "./context/DeliveryContext";
import { ShowFooterContextProvider } from "./context/ShowFooterContext";
import { CategoryContextProvider } from "./context/CategoryContext";
import { RenderHomeComponentsContextProvider } from "./context/RenderHomeComponentsContext";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Router>
      <RenderHomeComponentsContextProvider>
        <CategoryContextProvider>
          <ShowFooterContextProvider>
            <OrderStateContextProvider>
              <DeliveryContextProvider>
                <OrderProvider>
                  <Layout>
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/confirmation" element={<Confirmation />} />
                      <Route path="/error" element={<OrderError />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Layout>
                </OrderProvider>
              </DeliveryContextProvider>
            </OrderStateContextProvider>
          </ShowFooterContextProvider>
        </CategoryContextProvider>
      </RenderHomeComponentsContextProvider>
    </Router>
  );
}

export default App;
